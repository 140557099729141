$(document).ready(function(){
    if($('#header').hasClass('header-home')){
        console.log('STEP1')
        if($(window).scrollTop() > 320) {
            //scroll to top
            $('#header').removeClass('header-home-active');
            $(".home").removeClass('home-scroll');
        }else{
            $('#header').addClass('header-home-active');
            $(".home").addClass('home-scroll');
        }
    }
    var lastScrollTop = $(this).scrollTop();
    window.onscroll = function(e) {
        if(lastScrollTop < 0) {
            lastScrollTop = 0;
        }

        var st = $(this).scrollTop();
        if (st > lastScrollTop && st > 100) {
            //downscroll code
            $('#header').addClass('header-hide');
        } else {
            //upscroll code
            $('#header').removeClass('header-hide');
        }

        if($('#header').hasClass('header-home')){
            if($(window).scrollTop() > 220) {
                //scroll to top
                $('#header').removeClass('header-home-active');
                $(".home").removeClass('home-scroll');
            }else{
                $('#header').addClass('header-home-active');
                $(".home").addClass('home-scroll');
            }
        }
        lastScrollTop = st;
    };

    $('.plus-qty').click(function(){

        var qty = parseInt($(this).prev().val())+1;
        $(this).prev().val(qty)
    })
    $('.less-qty').click(function(){
        if( parseInt($(this).next().val())>1){
            var qty = parseInt($(this).next().val())-1;
            $(this).next().val(qty)
        }
    })

    $('#menu-mobile-image').click(function(){
        showMenuMobile(true);
    })

    $('#shoppingBag').click(function(){
        showShoppingBag();
        $('#bgStdType1').addClass('active');
        $('#body-page').addClass('noScroll');
        console.log('click');
    })

    $('#shoppingBagMobile').click(function(){
        showShoppingBag();
        $('#bgStdType1').addClass('active');
        $('#body-page').addClass('noScroll');
        console.log('click');
    })

    $('#bgStdType1').click(function(){
        closeMenuAndShoppingBag();
        $('#bgStdType1').removeClass('active');
        $('#body-page').removeClass('noScroll');
    })



    $('#bgMenuAndShoppingBag').click(function(){
        closeMenuAndShoppingBag();
    })

    function showShoppingBag() {
        showBgShoppingBagAndMenu();
        $('.content-bag').addClass('active');
    }

    function showMenuMobile() {
        showBgShoppingBagAndMenu();
        $('.menu-mobile').addClass('active');
    }

    function showBgShoppingBagAndMenu(){
        $('#bgMenuAndShoppingBag').addClass('active');
    }

    function closeMenuAndShoppingBag() {
        $('#bgMenuAndShoppingBag').removeClass('active');
        $('.content-bag').removeClass('active');
        $('.menu-mobile').removeClass('active');
    }

    $('#btn_send_newsletter').on("click",function(e){

        if(validardatos_newsletter() == 0)
        {

            $('#alert_error_contact_footer').html();
            var html = "";
            publicGenerateRecaptchaToken();
                $.ajax({
                url: $('#routeNewsletter').val(),
                method: 'POST',
                data: {
                    email: $('#email_newsletter').val(),
                    _token: "{{csrf_token()}}",
                    recaptcha: $('#recaptchaNewsletter').val(),
                    _token: $('#_token').val(),
                },

                success: function(data){
                    $('#newsletter_message').text(data.msg);
                    $('#newsletter_message').removeClass('text-danger');
                    $('#newsletter_message').addClass('text-success');
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) {
                    $('#newsletter_message').text(XMLHttpRequest.responseJSON.error);
                    $('#newsletter_message').removeClass('text-success');
                    $('#mail_error_newsletter').removeClass('show');
                    $('#newsletter_message').addClass('text-danger');
                }
            })
        }

    })

})

function validardatos_newsletter() {
    var testEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    var error = 0;

    $('#email_newsletter').removeClass('is-invalid');
    $('#mail_error_newsletter').removeClass('show');
    $('#politica_error_newsletter').removeClass('show');

    if (!testEmail.test($('#email_newsletter').val())) {
        error = 1;
        $('#email_newsletter').addClass('is-invalid');
        $('#mail_error_newsletter').addClass('show');
    }

    if ($('#politicaTrue_newsletter').is(':checked')) {
        $('#politicaValue_newsletter').val(1);
    } else {
        error = 1;
        $('#politica_error_newsletter').addClass('show');
    }

    return error;
}
